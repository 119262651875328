$body-bg: #333333;
$body-color: #FFFFFF;

@import "~bootstrap/scss/bootstrap";

header, footer {
  @extend .mx-auto;
}

html {
  @extend .h-100;
}

body {
  @extend .h-100;
  @extend .d-flex;
  @extend .justify-content-center;

  text-align: center;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

.page {
  @extend .d-flex;
  @extend .flex-column;
  @extend .h-100;
  @extend .p-3;
  @extend .mx-auto;
}

.site-links {
  @extend .d-flex;
  @extend .justify-content-around;

  .site-link {
    @extend .px-3;
  }
}
